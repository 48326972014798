.viewport {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.home {
  width: 100%;
  // height: 100%;
  // font-size: 3em;
  font-weight: 600;
  color: #1e252b;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // padding: 0 1.6em;
  .homescreen-container {
    width: 100%;
    height: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.favorite-bar {
  width: 100%;
  // height: 2em;
  padding: 0 0.5em 0.6em;
  display: flex;
  justify-content: space-around;
}

.homescreen {
  position: absolute;
  inset: 0;

  .pages-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.pages-container {
  .slick-list {
    flex-grow: 1;
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
  }

  .page-container {
    width: 100%;
    height: 100%;
    display: flex !important;
    // place-items: center;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    padding-bottom: 0;
  }

  .slick-dots {
    position: relative;
    bottom: 0;

    li {
      width: 6px;
    }
  }
}

.grid-page {
  width: 100%;
  max-height: 100%;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  // grid-template-rows: repeat(6, auto);

  .page-cell {
    box-sizing: border-box;
    border: solid 1px red;
  }
}

.cell-container {
  display: grid;
  position: relative;
  place-items: center;
  align-items: center;

  .app-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app-name {
    font-size: 0.8em;
    color: var(--med-txt);
    font-weight: 600;
    margin: 4px 0;
  }
}

.uicon[data-padd="true"] {
  background: #fefefe;

  & > * {
    transform: scale(0.75);
  }
}

.apps-wrapper, .recent-apps-container{
  position: absolute;
  inset: 0;
}

.full-height-swiper{
  height: 100%;

  .slick-list, .slick-track{
    height: 100%;
  }

  .slick-slide > div{
    height: 100%;
  }
}

.recent-apps-container{
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  pointer-events: none;

  &[data-hide="false"]{
    pointer-events: auto;
    opacity: 1;
  }
}

.recent-slider{
  height: 100%;
  transform: scale(1);
  box-sizing: border-box;
  transition: transform 200ms ease-in-out;

  &[data-hide="false"]{
    transform: scale(0.58);
  }

  .slick-list {
    margin: 0 -1.5em;
    overflow: visible;
  }

  .slick-slide > div {
    margin: 0 1.5em;
  }
}

.mini-app-container{
  background: #444;
  height: 100%;
  border-radius: 2em;
  // overflow: hidden;
  position: relative;
  opacity: 1;
  transform: translateY(0);
  transition-property: opacity, transform;

  &[data-rem="true"]{
    animation: fade-up 600ms ease-in-out;
  }
}

.closing-all-apps .mini-app-container{
  animation: fade-up 600ms ease-in-out;
}

@keyframes fade-up {
  0%{
    opacity: 1;
    transform: translateY(0);
  }

  33%{
    opacity: 0;
    transform: translateY(-50%);
  }

  100%{
    opacity: 0;
    transform: translateY(-50%);
  }
}

.mini-app-icon{
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
  z-index: 1;

  .uicon{
    width: 20%;
    border-radius: 40%;
    // width: max-content;
  }
}

.close-recent-container{
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0;
  display: grid;
  place-items: center;

  .close-all-btn{
    padding: 0.5em 3em;
    border-radius: 2em;
    color: var(--comp-txt);
    background: rgba(68, 68, 68, 0.25);
  }
}

.no-recent-container{
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  font-size: 1.2em;
  letter-spacing: 1px;
  word-spacing: 2px;
  color: var(--med-txt);
}

.mini-app-holder{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2em;
  overflow: hidden;
}

.mini-app-wrapper{
  height: 100%;
  border-radius: 0;
}

.recent-cover{
  position: absolute;
  inset: 0;
  z-index: 10;
}
