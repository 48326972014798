.google-home, .google-spage, .search-frame {
  padding: 1em 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.google-land, .google-spage{
  .namelogo {
    width: 100%;
  }

  .search-box-container {
    width: 96%;
    padding: 0.5em 1em;
    margin-top: 2em;
    margin-right: 2%;
    margin-bottom: 1em;
    border-radius: 2em;
    box-shadow: 1px 2px 8px rgba(74, 71, 71, 0.15);
  }

  .search-input {
    display: flex;
    align-items: center;

    svg {
      color: var(--light-txt);
    }

    .search-inp {
      flex-grow: 1;

      input {
        padding: 0.25em 0.5em;
      }

      fieldset {
        display: none;
      }
    }
  }

  .news-container {
    padding: 0 0.5em;

    .news-box {
      margin: 0.6em 0;
      color: var(--mid-txt);
    }

    .news-title {
      font-size: 0.96em;
      color: var(--med-txt);
    }

    .imageCont {
      border-radius: 0.8em;
      overflow: hidden;
      margin-bottom: 0.5em;
    }
  }
}

.google-spage{
  .search-box-container{
    box-sizing: border-box;
    padding: 0.5em 0.25em;
    margin: 0;
    width: 90%;
    border: 0 solid var(--comp-txt);
    border-radius: 0;
    border-bottom-width: 2px;
    box-shadow: none;

    .search-inp input{
      padding: 0.25em 1em;
    }
  }

  .search-suggestions{
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
    margin-top: 0.5em;
    color: var(--light-txt);

    .trending-search{
      margin-top: 1em;

      .sugg-title{
        margin-bottom: 0.5em;
      }
    }

    .sugg-title{
      padding: 0 0.5em;
      margin: 0 1.5em;
      font-size: 0.68em;
      letter-spacing: 1px;
      font-weight: 600;
    }

    .sugg-box{
      display: flex;
      padding: 0.5em 1.5em;

      span{
        margin-left: 1em;
        font-weight: 500;
        color: var(--txt-col);
      }
    }
  }
}

.search-frame{
  padding: 0;

  iframe{
    width: 100%;
    height: 100%;
  }
}
