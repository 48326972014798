.bottom-nav{
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 200ms ease-in-out;
}

.bt-nav-container{
  display: flex;
  justify-content: space-around;
  padding: 0.1em 1em;
  width: 100%;
  max-width: 50em;

  .uicon{
    padding: 0.5em;
    color: var(--med-txt);
    // filter: drop-shadow(1px 1px 4px rgba(254, 254, 254, 0.6));
  }

  &[data-invert="true"]{
    .uicon{
      color: var(--gray-txt);
    }
  }

  .bar-icon{
    transform: rotateZ(90deg);
  }
}
