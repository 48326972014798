// media player && status screen

.media-viewer-container, .status-container{
  position: absolute;
  inset: 0;
  background: #010101;
  transition: all 200ms ease-in-out;

  &[value="hide"] {
    opacity: 0;
    pointer-events: none;
  }

  .whatsapp-top-nav {
    background: none;
    color: var(--comp-txt);
    position: absolute;
    top: 0;
    padding: 0.5em;
    z-index: 1;

    .chat-name {
      span:nth-child(1) {
        font-size: 1.2em;
      }

      span:nth-child(2) {
        font-size: 0.64em;
        line-height: 0.75em;
      }
    }
  }

  .media-screen {
    display: grid;
    place-items: center;
    height: 100%;

    .vidCont {
      height: 100%;
    }

    .video-control-container {
      .MuiSlider-thumb,
      .MuiSlider-track {
        background: var(--light-teal);
      }
    }
  }
}

.status-container{

  .whatsapp-top-nav{
    display: flex;
    flex-direction: column;

    .imageCont{
      margin: 0 0.5em;
    }
  }

  .status-msg-container{
    height: 100%;
    background: #2e92ee;
    display: grid;
    place-items: center;
    padding: 1em;
    text-align: center;
    font-size: 2em;
    color: var(--comp-txt);
  }

  .caption-container{
    position: absolute;
    bottom: 1.5em;
    padding: 0.5em 0;
    width: 100%;
    color: var(--white);
    background: rgba(1, 1, 1, 0.5);
    font-weight: 500;
    text-align: center;
  }
}

.progress-bar-container{
  display: flex;
  height: 2px;
  border-radius: 1em;
  margin-bottom: 0.5em;

  .progress-bar{
    flex-grow: 1;
    background: rgba(136, 136, 136, 0.5);
    margin: 0 1.5px;
    overflow: hidden;

    &[data-lit="true"]{
      background: var(--white);
    }

    .progress-fill{
      width: 0;
      height: 100%;
      background: var(--white);
      transition: width 400ms linear;
    }
  }
}

// chat screen

.chat-profile {
  display: flex;
  align-items: center;
  padding: 0.2em;
  border-radius: 2em;
}

.chat-screen-container {
  position: absolute;
  inset: 0;
  --msg-incoming: #fefefe;
  --msg-outgoing: #e7ffdd;
  --msg-toast: #d3eaf7;
  // --msg-outgoing: #e1ffc7;
  .chat-screen {
    flex-grow: 1;
    overflow: hidden;
  }

  .chat-input-container {
    display: flex;
    align-items: flex-end;
    padding: 0.2em 0.4em;

    .chat-input-wrapper {
      display: flex;
      align-items: flex-end;
      background: var(--white);
      color: var(--mid-txt);
      border-radius: 24px;
      padding: 0.2em 0.4em;
      flex-grow: 1;

      .uicon {
        margin: 0.36em 0.24em;
      }
    }

    .chat-input-field {
      padding: 0.2em;
      flex-grow: 1;

      & > div {
        border: none;
        padding: 0.3em 0;
        color: var(--med-txt);
      }

      fieldset {
        display: none;
      }
    }

    .mic-icon,
    .send-icon {
      color: var(--white);
      background: var(--light-teal);
      height: 45px;
      aspect-ratio: 1 / 1;
      margin-left: 0.5em;
      border-radius: 4em;
    }

    .mic-icon {
      animation: pop-up ease-in-out 200ms;
    }

    .send-icon {
      animation: pop-up2 ease-in-out 200ms;
    }
  }
}

.chat-container {
  position: relative;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0.5em 1em;

  .chat-scroll-container {
    // position: absolute;
    min-height: 100%;
    height: max-content;
  }

  .chat-toast {
    position: relative;
    display: flex;
    flex-direction: column;
    // margin: 0.5em 0;
    &[value="0"] {
      align-items: flex-start;
    }

    &[value="1"] {
      align-items: center;
    }

    &[value="2"] {
      align-items: flex-end;
    }
  }

  .msg-box {
    position: relative;
    min-width: 4em;
    max-width: 80%;
    padding: 0.2em 0.3em;
    border-radius: 0.5em;
    margin: 0.125em 0;
    font-size: 0.9em;

    .imageCont {
      margin-top: 0.125em;

      img {
        border-radius: 0.5em;
      }
    }

    .vidCont {
      margin-top: 0.125em;
      border-radius: 0.5em;
      overflow: hidden;
    }

    pre {
      padding: 0 0.2em;
      font-family: inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
      max-width: 100%;
      color: var(--med-txt);

      a {
        color: var(--link-blue);
        font-weight: 500;
        white-space: pre-wrap;
        overflow-wrap: break-word;
      }
    }

    .chat-date {
      margin-left: 0.5em;
      margin-top: 0.5em;
      float: right;
      font-size: 0.7em;
      font-weight: 500;
      color: var(--sat-txt);
      fill: var(--sat-txt);
      display: flex;
      align-items: center;

      .uicon {
        margin-left: 0.2em;
        margin-bottom: 2px;
      }
    }

    & > .chat-date {
      position: absolute;
      bottom: 0.5em;
      right: 1em;
      -webkit-text-stroke-color: #fefefe;
      -webkit-text-stroke-width: thin;
    }
  }

  .in-msg {
    background: var(--msg-incoming);
  }

  .out-msg {
    background: var(--msg-outgoing);
  }

  .first-msg {
    margin-top: 0.5em;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 1em;
      height: 1.2em;
      z-index: -1;
    }
  }

  .in-msg.first-msg::after {
    left: -0.5em;
    background: var(--msg-incoming);
    clip-path: polygon(0 0,100% 0,100% 100%);
  }

  .out-msg.first-msg::after {
    right: -0.5em;
    background: var(--msg-outgoing);
    clip-path: polygon(0 0,100% 0,0 100%);
  }

  .msg-toast {
    margin: 0.5em 0;
    width: auto;
    max-width: 90%;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    font-size: 0.72em;
    text-align: center;
    background: var(--msg-toast);
    box-shadow: 1px 1px 1px rgba(18, 18, 18, 0.25);
    word-spacing: 2px;
  }
}

// all status
.status-date {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--light-txt);
}

.my-status {
  padding: 0.5em 0;

  .chat-status {
    margin: 0;
  }
}
