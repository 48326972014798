.app-wrapper{
  pointer-events: auto;
  height: 100%;
  border-radius: 0;
  transition: all 200ms ease-in-out;
  animation: popup ease-in-out 200ms;

  &[data-open="false"]{
    border-radius: 1em;
    transform: scale(0.58);
    opacity: 0;
    pointer-events: none;

    *{
      pointer-events: none;
    }
  }
}

.app-inner-wrapper{
  height: 100%;
  overflow: hidden;
  position: relative;
}

.app-icon-container{
  height: 70%;
  display: grid;
  place-items: center;
}

@keyframes popup {
  from{
    border-radius: 1em;
    transform: scale(0.8);
    opacity: 0;
  }

  to{
    border-radius: 0;
    transform: scale(1);
    opacity: 1;
  }
}

.full-hide{
  height: 100%;
  overflow: hidden;
}
