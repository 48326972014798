.app-wrapper{
  --app-bg: #fefefe;
  background: var(--app-bg);
}

.whatsapp-wrapper, .playstore-wrapper{
  --app-bg: #fefefe;
  background: var(--app-bg);

  --teal: #128c7e;
  --teal-dark: #075e54;
  --light-teal: #02a884;
  --light-green: #25d366;
  --white: #fefefe;
  --blue: #34b7f1;
  --check-blue: #34b7f1;
  --link-blue: #5bc2df;
  --bubble-green: #dcf8c6;
}

.whatsapp-viewport, .playstore-viewport{
  --app-bg: #fefefe;
  --teal: #128c7e;
  --teal-dark: #075e54;

  --statusbg: var(--teal);
  --navbg: var(--app-bg);
}

.youtube-wrapper{
  --app-bg: #fefefe;
  --pm-txt: #121212;
  background: var(--app-bg);
  // color: #fefefe;
}

.youtube-viewport{
  --app-bg: #fefefe;
  --statusbg: var(--app-bg);
  --navbg: var(--app-bg);
}


.google-wrapper{
  --app-bg: #fefefe;
  background: var(--app-bg);
}

.google-viewport{
  --app-bg: #fefefe;

  --statusbg: var(--app-bg);
  --navbg: var(--app-bg);
}
