.background {
  min-width: 100vw;
  min-height: 100%;
  background-color: #fefefe;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.2s ease;
}

.app-overlay, .brightness-overlay{
  position: absolute;
  pointer-events: none;
  inset: 0;
}

.brightness-overlay{
  background: #000;
}
